import React from "react";

function Blog() {
  return (
    <>
      <section>
        <div className="container">
          <h3>Coming soon !! 😄</h3>
        </div>
      </section>
    </>
  );
}

export default Blog;
