import React from 'react'

function CA_companies() {
  return (
    <div>
      <h3>Coming soon !! 😄</h3>
    </div>
  )
}

export default CA_companies
