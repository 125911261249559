import React from 'react'

function Political_news() {
  return (
    <div>
      <h3>Coming soon !! 😄</h3>
    </div>
  )
}

export default Political_news
