import React from 'react'

function Business_news() {
  return (
    <div>
      <h3>Coming soon !! 😄</h3>
    </div>
  )
}

export default Business_news
