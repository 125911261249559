import React from 'react'

function Analysis_companies() {
  return (
    <div>
      <h3>Coming soon !! 😄</h3>
    </div>
  )
}

export default Analysis_companies
